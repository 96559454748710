
export function VideoPlayer() {
  return (
    <div className="w-screen h-screen flex justify-center items-center bg-black">
      <video className="w-full h-full object-cover" autoPlay controls>
        <source src="/rannis-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}