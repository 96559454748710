import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-4">
            {" "}
            <img src="img/asgeir.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="about-text">
              <h2>About Us</h2>
              <p>Bancony is an Icelandic FinTech specializing in conversational banking, founded by <a href="https://www.linkedin.com/in/asgeiroa/">Ásgeir Örn Ásgeirsson</a>. Ásgeir is also a co-founder of <a href="https://meniga.com">Meniga</a>, a successful FinTech company that develops and sells digital banking solutions to some of the largest banks in Europe and beyond. With extensive experience in building and scaling startups, Ásgeir has held various leadership roles at Meniga over the past 16 years, including Chief Technical Officer, Board Member, Chief Growth Officer, and SVP of Customer Success.</p>
              {/* <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
