import React from "react";

export const HowItWorks = (props) => {
  return (
    <div id="services">
      <div className="container">
        <div className="section-title">
          <h2>How it works</h2>
          <p>
           Bancony is a best-in-class conversational agent tailored for digital banking data and actions. It is an AI powered agent trained to understand personal and business banking data and operations through well designed APIs that support digital banking use cases such as transfers, bill payments, card management, new product onboarding, and inquiries about expenses, income, savings, account balances, budgets, and cash flow. Additionally, the agent is enriched with bank’s own knowledge bases and data sources, enabling it to provide customer support and personalized product recommendations alongside everyday banking interactions.
          </p>
          <p>
            Equipped with both short-term and long-term memory, the agent facilitates context-aware and highly personalized conversations. It continuously learns from customer interactions and feeds this knowledge into the bank’s CRM or personalization platforms. Conversations can be initiated by both the customer and the bank, with bank-initiated engagements potentially leading to upselling opportunities for new products and services.            
          </p>
          <p>
             Designed for seamless integration, Bancony’s conversational agent can be embedded into digital banking channels via customizable widgets using well-documented SDK. Banks can integrate it with minimal effort, ensuring a fast time to market. The solution is available as a SaaS offering but can also be deployed within a bank’s own infrastructure to meet stringent security requirements. Built using cutting-edge open-source agent frameworks with LLMs at its core, our technology leverages LLM Tool Calling and Retrieval-Augmented Generation (RAG) to enhance LLM reasoning with consumer data and bank-specific knowledge bases.
          </p>
        </div>
      </div>
    </div>
  );
};
