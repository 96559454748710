import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

export const Contact = (props) => {
  const [formData, setFormData] = useState({ name: "", email: "", organization: "", message:"" });
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    emailjs
      .sendForm("service_l2jjfbj", "template_frw1ikx", e.target, "QFioY8LqZ3Uf1MhQv")
      .then(
        (result) => {
          setTimeout(() => setSubmitted(false), 5000);
          setFormData({ name: "", email: "", organization: "", message:"" });
          setSubmitting(false);
          setSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
              <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                        disabled={submitting}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                        disabled={submitting}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        id="organization"
                        name="organization"
                        value={formData.organization}
                        className="form-control"
                        placeholder="Organization"
                        onChange={handleChange}
                        disabled={submitting}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                    value={formData.message}
                    disabled={submitting}
                    ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                {submitted && <div className="submitted">Thank you for getting in touch. We will get back soon.</div>}
                <button type="submit" className="btn btn-custom btn-lg" disabled={submitted || submitting}>
                {submitted ? "Message Sent" : (submitting ? "Sending Message" : "Send Message")} 
                </button>
                
              </form>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2025 Bancony ehf. All rights reserved.
          </p>
        </div>
      </div>      
    </div>
  );
};
