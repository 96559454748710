import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Contact } from "./components/contact";
import { HowItWorks } from "./components/how-it-works";
import { VideoPlayer } from "./components/video";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {

  const [route, setRoute] = useState(window.location.pathname);

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
    const handleRouteChange = () => {
      setRoute(window.location.pathname);
    };

    window.addEventListener("popstate", handleRouteChange);
    return () => window.removeEventListener("popstate", handleRouteChange);
  }, []);

  return (
    <div>
      {route === "/rannis-video" && <VideoPlayer />}
      {route === "/" && <Navigation />}
      {route === "/" && <Header data={landingPageData.Header} />}
      {route === "/" && <Features data={landingPageData.Features} />}
      {route === "/" && <About data={landingPageData.About} />}
      {route === "/" && <HowItWorks data={landingPageData.Services} />}
      {route === "/" && <Contact data={landingPageData.Contact} />}
    </div>
  );
};

export default App;
